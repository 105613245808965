import React, {useEffect, useState} from "react";
import PieChart from "../PieD/PieTestD";
import StackedBarChart from "../TestStackedD/TestStackedBarChart";
import { GeoChart } from "../TestMapD/GeoChart";
import {BarLineChart} from "../LineBarChart/Notice/LineBarChart";
import Activity from "../ActivityDiagram/Activity";
import Summary from "../SummaryD/Summary";
import ManyLineD from "../ManyLineChart/ManyLineChart";
import TreeMapDiagramm from "../TesTreeD/TreeDiagramm";
import TopDiagramm from "../DonutD/TopChart";
import RoleDiagramm from "../DonutD/ResponsableRoleChart";
import {useDispatch, useSelector} from "react-redux";
import DraggableDiagram from "./DraggableDiagram";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import {setGridTemplateAreas, swapDiagrams} from "../../../service/reducers/DiagrammConfig/DiagramConfigSlice";
import ContractStackedBarChart from "../ContractStackedChart/StackedLineChart";
import {ContractBarLineChart} from "../LineBarChart/Contracts/ContractLineBarChartV1";
import IndicatorDiagramm from "../IndicatorDiagram/indicatorDiagram";
import SunDiagramm from "../SunkeyChart/SunKeyChart";
import BubbleDiagramm from "../TestBubbleD/BubbleDiagramm";
import SunBurstDiagramm from "../SunBurstChart/SunBurstChart";
import IspStackedBarChart from "../ContractStackedChart/StackedLIneShort";
import {Helmet} from "react-helmet";
import {updatePageTitle} from "../../../utils/getTitleName";
import ManyLineMonthD from "../ManyLineKMonthChart/ManyLineMonthChart";
import {setZoomedD} from "../../../service/reducers/ZommedDSlice";

const DContainer = () => {
    const dispatch = useDispatch();
    const [zoomedDiagram, setZoomedDiagram] = useState(null);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { diagrams, gridTemplateAreas } = useSelector((state) => state.dConfig[activeTab]);
    // eslint-disable-next-line
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleZoomClick = (type) => {
        setZoomedDiagram((prevType) => {
            const newType = (prevType === type ? null : type);
            dispatch(setZoomedD(newType));
            return newType;
        });
    };

    const getGridTemplateAreas = (width) => {
        if (width < 768) {
            return {
                'Извещения': `
            "col1"
            "col2"
            "col3"
            "col4"
            "col5"
            "col6"
            "col7"
            "col8"
            "col9"
            "col10"
            "col11"`,
                'Контракты': `
            "col1"
            "col2"
            "col3"
            "col4"
            "col5"
            "col6"
            "col7"
            "col8"
            "col9"
            "col10"
            "col11"`,
                'Исполнение': `
            "col1"
            "col2"
            "col3"
            "col4"
            "col5"
            "col6"
            "col7"
            "col8"
            "col9"
            "col10"
            "col11"`
            };
        }
        else if (width < 1024) {
            return {
                'Извещения': `
                "col1 col2"
                "col3 col5"
                "col4 col4"
                "col6 col6"
                "col7 col7"
                "col8 col8"
                "col9 col9"
                "col10 col10"
                "col11 col11"`,
                'Контракты': `
                "col1 col5"
                "col2 col2"
                "col3 col3"
                "col4 col7"
                "col6 col6"
                "col8 col8"
                "col9 col9"
                "col10 col10"`,
                'Исполнение': `
                "col1 col5"
                "col2 col2"
                "col3 col4"
                "col6 col6"
                "col7 col7"
                "col8 col8"
                "col9 col9"
                "col10 col10"
                "col11 col11"`
            };
        }
        else {
            return {
                'Извещения': `
                "col1 col2 col3"
                "col4 col4 col5"
                "col6 col7 col7"
                "col8 col8 col9"
                "col10 col11 col11"`,
                'Контракты': `
                  "col1 col2 col2"
                  "col3 col3 col4"
                  "col5 col6 col6"
                  "col7 col8 col8"
                  "col9 col9 col10"`,
                'Исполнение': `
                  "col1 col2 col2"
                  "col3 col4 col5"
                  "col6 col7 col7"
                  "col8 col9 col9"
                  "col10 col10 col11"`,
            };
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            const gridTemplates = getGridTemplateAreas(window.innerWidth);
            Object.keys(gridTemplates).forEach(tabName => {
                dispatch(setGridTemplateAreas({
                    tabName,
                    gridTemplateAreas: gridTemplates[tabName]
                }));
            });
        };
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const componentsMap = {
        'Summary': Summary,
        'PieChart': PieChart,
        'TopDiagramm': TopDiagramm,
        'StackedBarChart': StackedBarChart,
        'Activity': Activity,
        'SoloLineD': ManyLineMonthD,
        'GeoChart': GeoChart,
        'BarLineChart': BarLineChart,
        'ManyLineD': ManyLineD,
        'RoleDiagramm': RoleDiagramm,
        'TreeMapDiagramm': TreeMapDiagramm,
        'ContractStackedBarChart': ContractStackedBarChart,
        'ContractPayMonth': ContractBarLineChart,
        'Indicator': IndicatorDiagramm,
        'SunKey': SunDiagramm,
        'Scatter': BubbleDiagramm,
        'SunBurst': SunBurstDiagramm,
        'ispOkpd' : IspStackedBarChart,
    };

    const getGridColumns = (windowWidth) => {
        if (windowWidth >= 1024) {
            return 'minmax(80px, 1fr) minmax(80px, 1fr) minmax(80px, 1fr)';
        } else if (windowWidth >= 768) {
            return 'minmax(80px, 1fr) minmax(80px, 1fr)';
        } else {
            return 'minmax(80px, 1fr)';
        }
    };
    const renderDiagrams = () => {
        const gridColumns = getGridColumns(window.innerWidth);
        if (zoomedDiagram) {
            const Component = componentsMap[zoomedDiagram];
            if (Component) {
                return (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateRows: '1fr',
                            width: '100%',
                        }}
                    >
                        <Component
                            onZoomClick={() => handleZoomClick(zoomedDiagram)}
                            zoomedDiagram={zoomedDiagram}
                        />
                    </div>
                );
            }
        }

        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateAreas: gridTemplateAreas,
                    gridTemplateColumns: gridColumns,
                    gridTemplateRows: '1fr 1fr 1fr',
                    gap: '10px',
                    width: '100%',
                }}
            >
                {diagrams.map(({ type, gridArea }) => {
                    const Component = componentsMap[type];
                    return (
                        <div key={type} style={{ gridArea }}>
                            <DraggableDiagram
                                type={type}
                                Component={Component}
                                onZoomClick={handleZoomClick}
                                moveDiagram={moveDiagram}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };
    const moveDiagram = (fromType, toType) => {
        dispatch(swapDiagrams({ fromType, toType, tabName: activeTab }));
    };

    return (
        <div>
            <Helmet>
                <title>{updatePageTitle(`Дашборд (${activeTab})`)}</title>
            </Helmet>
            <DndProvider backend={HTML5Backend}>
                {renderDiagrams()}
            </DndProvider>
        </div>
    );
};

export default DContainer;
