import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    zoomedDiagram: null,
};

const zoomedDiagramSlice = createSlice({
    name: 'zoomedDiagram',
    initialState,
    reducers: {
        setZoomedD: (state, action) => {
            state.zoomedDiagram = action.payload;
        },
    }
});

export const { setZoomedD } = zoomedDiagramSlice.actions;
export default zoomedDiagramSlice.reducer;
