import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {
    addPieName,
    toggleSelectedSlice,
    updateSelectedSliceSize
} from "../../../service/reducers/PieChart/PieChartSegmentSlice";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import {fetchPieData, setShowCount} from "../../../service/reducers/PieChart/PieChartSlice";
import Spinner from "../../TestPages/Spinner";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {formatNumber} from "../../../utils/countFunc";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import {setActiveLegendItem} from "../../../service/reducers/legendItemsClick";
import localStorageService from "../../../service/localStorage/localStorageService";
import {cancelAllPendingRequests} from "../../../api/api";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
import tooltipNames from "../../../utils/tooltipTitles.json"
const PieChart = ({ onZoomClick, zoomedDiagram }) => {
    const selectedSlice = useSelector(state => state.pie.selectedSlice);
    const selectedSliceSize = useSelector(state => state.pie.selectedSliceSize);
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const arcGenerator = useRef();
    const dynamicRadius = useRef(0);
    const PIE_OFFSET = 10;
    const { width, height } = useResizeObserver({ ref });
    const { PieData, showCount, loading } = useSelector((state) => state.pieChart);
    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const headerTitle = activeTab === 'Извещения'
        ? `Соотношение способов определения поставщиков ${showCount === 'count' ? "(от кол-ва)" : "(от суммы)"}`
        : activeTab === 'Контракты'
            ? `Источники финансирования контрактов ${showCount === 'count' ? "(от кол-ва)" : "(от суммы)"}`
            : activeTab === 'Исполнение'
                ? `Статусы исполнения контрактов ${showCount === 'count' ? "(от кол-ва)" : "(от суммы)"}`
                : `Неизвестная вкладка`;
    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'change', icon: icons.change, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const top = useSelector((state) => state.activitySlice);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const colors = localStorageService.getItem('colors');
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            topBody,
            trimCode,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            filterOkpd,
            selectedCountryLine,
            selectedKbrSegments,
            RangeDT,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };
        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }
        dispatch(fetchPieData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,selectedKbrSegments,contractTrimCode,filterOkpd,dateChanger,selectedOkpd,selectedProduct,showCount,activeRegions, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedDonutSegmetsV1, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    useEffect(() => {
        if ( loading === 'successful' && width && height && PieData) {
            const newRadius = Math.min(width, height) * 0.35;
            selectedSlice.forEach((label) => {
                const size = selectedSliceSize[label] ? newRadius + PIE_OFFSET : dynamicRadius.current;
                dispatch(updateSelectedSliceSize({ label, size }));
            });
            dynamicRadius.current = newRadius;
            if (showCount === 'count') {
                const newPieData = PieData.map(item => {
                    return {
                        label: item.label,
                        value: item.extra[0].value
                    };
                });
                createPieChart(newPieData);
            } else {
                createPieChart(PieData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, PieData, selectedSlice, selectedSliceSize]);

    useEffect(() => {
        if (!ref.current) return;
        d3.select(ref.current)
            .selectAll('path')
            .transition()
            .duration(800)
            .attrTween('d', function (d) {
                const i = d3.interpolate(d.startAngle, d.endAngle);
                return function (t) {
                    const interpolated = { ...d, endAngle: i(t) };
                    if (selectedSlice.includes(d.data.label)) {
                        return d3.arc().innerRadius(0).outerRadius(dynamicRadius.current + PIE_OFFSET)(interpolated);
                    }
                    return arcGenerator.current(interpolated);
                };
            })
            .attr('opacity', (d) => selectedSlice.length === 0 || selectedSlice.includes(d.data.label) ? 1 : 0.5);
    }, [width, height, PieData, selectedSlice, selectedSliceSize]);

    useEffect(() => {
        if (!ref.current) return;
        const svg = d3.select(ref.current).select("svg");
        const pieChartGroup = svg.select('g');

        pieChartGroup
            .selectAll('text')
            .each(function (d) {
                updateText(d3.select(this), d);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlice]);

    const getTextSize = (angle) => {
        const percentage = (angle / (2 * Math.PI)) * 100;
        const baseSize = dynamicRadius.current / 73;
        if (percentage < 1) return '0px';
        if (percentage < 5) return `${8 * baseSize}px`;
        if (percentage < 10) return `${8 * baseSize}px`;
        return `${10 * baseSize}px`;
    };

    const updateText = (textSelection, d) => {
        const percentage = (d.endAngle - d.startAngle) / (2 * Math.PI) * 100;
        const radiusThreshold = 63;
        const isSmallSegment = dynamicRadius.current * (d.endAngle - d.startAngle) <= radiusThreshold;

        if (isSmallSegment && !selectedSlice.includes(d.data.label)) {
            textSelection.text('');
            return;
        }

        const color = isSmallSegment ? 'var(--text-color)' : 'white';
        const outerRadius = isSmallSegment ? dynamicRadius.current + PIE_OFFSET + (130 * dynamicRadius.current / 90) : dynamicRadius.current;
        const centroid = d3.arc().innerRadius(0).outerRadius(outerRadius).centroid(d);
        const angle = d.endAngle - d.startAngle;
        const fontSize = getTextSize(angle);

        textSelection
            .attr('x', centroid[0])
            .attr('y', centroid[1])
            .attr('dy', '0.33em')
            .text(`${percentage.toFixed(1)}%`)
            .attr('font-family', 'Golos regular')
            .attr('fill', color)
            .attr('font-size', fontSize)
            .attr('text-anchor', 'middle');
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const tooltipConfig = tooltipNames.PieChart.Tabs[activeTab];
        const tooltipSize = calculateTooltipSize(d.data.extra);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        let tooltipText = [
            { label: tooltipConfig.label, value: d.data.label },
        ];

        if (showCount === 'sum') {
            tooltipText.push({ label: tooltipConfig.value, value: formatCurrency(d.data.value) });
        } else if (showCount === 'count') {
            tooltipText.push({ label: tooltipConfig.count, value: `${d.data.value} шт` });
        }

        setTooltip({
            x,
            y,
            text: tooltipText
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const handleLegendItemClick = (label) => {
        cancelAllPendingRequests()
        const size = dynamicRadius.current + PIE_OFFSET;
        const color = activeColors.find((_, index) => PieData[index]?.label === label);
        dispatch(toggleSelectedSlice({ label, size, color }));
        dispatch(addPieName(headerTitle));
    };
    const sortedData = [...PieData].sort((a, b) => b.value - a.value);
    const createLegendColors = () => {
        if (activeTab === 'Извещения' && PieData) {
            return sortedData.map(item => {
                const colorInfo = item.extra?.find(ex => ex.label === 'color');
                return colorInfo ? colorInfo.value : 'black';
            });
        } else {
            return activeColors.slice(0, PieData.length);
        }
    };

    const legendColors = createLegendColors();

    const findShortNameForLabel = (label) => {
        const colorEntry = colors.find(color => color.name === label);
        return colorEntry ? colorEntry.short_name : label;
    };

    const legendData = sortedData.map(item => ({
        label: findShortNameForLabel(item.label),
        fullLabel: item.label
    }));
    const createPieChart = (data) => {
        if (!Array.isArray(data) || data.length === 0) return;
        const sortedData = [...data].sort((a, b) => b.value - a.value);
        d3.select(ref.current).select("svg").remove();
        const colorScale = d3.scaleOrdinal()
            .domain(sortedData.map(d => d.label))
            .range(sortedData.map((d, index) => {
                const colorInfo = d.extra?.find(ex => ex.label === 'color');
                return colorInfo ? colorInfo.value : activeColors[index % activeColors.length];
            }));

        const pie = d3.pie().value((d) => d.value);
        const data_ready = pie(sortedData);

        arcGenerator.current = d3.arc().innerRadius(0).outerRadius(dynamicRadius.current);

        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const pieChartGroup = svg.append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const segmentGroup = pieChartGroup
            .selectAll('g.segment')
            .data(data_ready, (d) => d.data.label)
            .enter()
            .append('g')
            .attr('class', 'segment')
            .on('click', function(event, d) {
                cancelAllPendingRequests()
                if (data.length === 1) return;
                const size = dynamicRadius.current + PIE_OFFSET;
                const color = colorScale(d.data.label);
                dispatch(toggleSelectedSlice({ label: d.data.label, size, color }));
                dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: d.data.label }));
                dispatch(addPieName(headerTitle));
            });

        segmentGroup.append('path')
            .attr('d', arcGenerator.current)
            .attr('fill', (d) => colorScale(d.data.label))
            .attr('stroke', 'var(--container-bg-color)')
            .attr('stroke-width', '0.5')
            .attr('opacity', (d) => selectedSlice.length === 0 || selectedSlice.includes(d.data.label) ? 1 : 0.5)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut);

        segmentGroup.append('text')
            .each(function(d) {
                updateText(d3.select(this), d);
            })
            .style("user-select", "none")
            .style("-moz-user-select", "none")
            .style("-webkit-user-select", "none")
            .style("-ms-user-select", "none");
    };

    return (
        <div className={`${style.container} ${selectedSlice.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram small-chart`} style={zoomedDiagram ? { height: "75vh" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    activeMode={showCount}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'sum') {
                            dispatch(setShowCount('sum'));
                        } else if (mode === 'count') {
                            dispatch(setShowCount('count'));
                        }
                    }}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={headerTitle} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={legendColors} onLegendItemClick={handleLegendItemClick} />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}:</strong>{formatNumber(item.value)}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default PieChart;
