import styles from './Layout.module.css';
import SliderMenu from "../SliderMenu/SliderMenu";
import {useModal} from "../../hook/useModal";
import Modal from "../Modal/ModalComponent/Modal";
import DashBoard from "./DashBoard/DashBoard";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserPrivileges, selectPrivileges} from "../../service/reducers/PrivelegiesSlice";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const Layout = () => {
    const { isModalVisible, modalContent, setIsModalVisible } = useModal();
    const [isLoadingPrivileges, setIsLoadingPrivileges] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const privileges = useSelector(selectPrivileges);
    const hasGraphsViewPrivilege = privileges.includes('graphs.view');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(fetchUserPrivileges(token))
                .unwrap()
                .then(() => setIsLoadingPrivileges(false))
                .catch(error => {
                    setIsLoadingPrivileges(false);
                });
        } else {
            setIsLoadingPrivileges(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingPrivileges && !hasGraphsViewPrivilege && (location.pathname === '/content' || location.pathname === '/content/dashBoard')) {
            navigate('/personal');
        }
    }, [isLoadingPrivileges, hasGraphsViewPrivilege, location.pathname, navigate]);

    const renderMainContent = () => {
        switch (location.pathname) {
          /*  case '/personal':
                return <MasterPersonal />;*/
            case '/content':
            case '/content/dashboard':
                return <DashBoard />;
          /*  case '/content/search':
                return <TestSearch />;
            case '/content/ratings':
                return <TestRatings />;*/
            default:
                return;
        }
    };

    return (
        <div className={styles.appContainer}>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                {modalContent}
            </Modal>
            <div>
                <SliderMenu/>
            </div>
            {renderMainContent()}
        </div>
    );
};

export default Layout;
