/* eslint no-unused-expressions: "off" */
import { useEffect } from 'react';

function YandexMetrika() {
    useEffect(() => {
        // Declaring the ym function as a global to avoid ESLint no-undef errors
        /* global ym */

        (function(m, e, t, r, i, k, a) {
            m[i] = m[i] || function() { (m[i].a = m[i].a || []).push(arguments); };
            m[i].l = 1 * new Date();
            // eslint-disable-next-line
            k = e.createElement(t), a = e.getElementsByTagName(t)[0];
            k.async = 1; k.src = r; a.parentNode.insertBefore(k, a);
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(96319377, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        });
    }, []);

    return null;
}

export default YandexMetrika;
