import React, {useState} from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Box } from '@mui/material';
import style from './AuthComponent.module.css'
import ico from '../../common/IcoBig.svg'
import {useDispatch} from "react-redux";
import {fetchUserPrivileges} from "../../service/reducers/PrivelegiesSlice";
import CircularProgress from "@mui/material/CircularProgress";
import {getDateForPickers} from "../../api/api";
const LoginForm = ({ onLoginSuccess }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const response = await axios.post('https://test-auth-goszakupki.agatha.pw/login', values, {
                    withCredentials: true
                });
                const tokenString = response.data.token;
                localStorage.setItem('token', tokenString);
                await sendMeRequest(tokenString);
                setIsLoading(false);
            }
            catch (error) {
                setIsLoading(false);
                if (error.response && error.response.status === 400) {
                    formik.setFieldError('password', 'Неверные данные для входа');
                }
            }
        },
    });
    const sendMeRequest = async (tokenString) => {
        try {
            const response = await dispatch(fetchUserPrivileges(tokenString));
            if (response.meta.requestStatus === 'fulfilled') {
                const dateForPickersResponse = await getDateForPickers();
                const sortedDateForPickersResponse = dateForPickersResponse.sort((a, b) => {
                    if (a.type === 'start_date' && b.type === 'end_date') {
                        return -1;
                    } else if (a.type === 'end_date' && b.type === 'start_date') {
                        return 1;
                    }
                    return 0;
                });
                localStorage.setItem('dateForPickers', JSON.stringify(sortedDateForPickersResponse));
                onLoginSuccess();
                navigate('/content/dashboard');
            } else {
                throw new Error('Неудачная авторизация');
            }
        }
        catch (err) {

        }
    };

    return (
        <div>
            <div className={style.background}/>
            <Container maxWidth="xs" sx={{
                zIndex: 9999 ,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Box
                    minHeight="30vh"
                    sx={{
                        backgroundColor: 'transparent',
                        padding: '3px',
                        borderRadius: '10%',
                        boxShadow: 4,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="30vh"
                        sx={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '9.4%',
                            boxShadow: 10,
                        }}
                    >
                        <img src={ico} alt="Иконка" />
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="username"
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.password ? (
                                    <div style={{ color: '#E94F74' }}>{formik.errors.password}</div>
                                ) : null}
                                <Button className={style.authButton} type="submit" fullWidth variant="contained" sx={{ mt: 3, textTransform: 'none' }}>
                                    Войти
                                </Button>
                                {isLoading && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                        <CircularProgress size={24} />
                                        <Box sx={{ mt: 2 }}>Загрузка...</Box>
                                    </Box>
                                )}
                            </form>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default LoginForm;
