import React, {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import style from "./TreeMapDiagramm.module.css";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import {treeMapColors} from "../../../utils/colors"
import Spinner from "../../TestPages/Spinner";
import {useDispatch, useSelector} from "react-redux";
import useResizeObserver from "use-resize-observer";
import {
    addTreeMapName,
    fetchTreeMapData,
    increaseLoadedItemsCount
} from "../../../service/reducers/TreeMapChart/TreeMapChartSlice";
import styles from "../TestMapD/GeoChart.module.css"
import {
    setTreeMapMode,
    toggleSelectedSegment
} from "../../../service/reducers/TreeMapChart/TreeMapChartSegmentSlice";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import {cancelAllPendingRequests} from "../../../api/api";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
import tooltipNames from "../../../utils/tooltipTitles.json"
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {formatCurrency} from "../../../utils/rubbleFunc";

const TreeMapDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const tooltipRef = useRef(null);
    const ref = useRef();
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const { TreeMapData, loading, loadedItemsCount} = useSelector((state) => state.treemap);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const slidePoz = useSelector(state => state.searchSwitcher.position);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);

    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedTreeMapLabels = selectedSegments.map(segment => segment.label);
    const { treeMapMode } = useSelector((state) => state.treeMapSlice);
    const top = useSelector((state) => state.activitySlice);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || filterOkpd.length > 0) && activeTab!== 'Извещения';
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);

    let headerTitle;

    if (activeTab === 'Извещения') {
        headerTitle = 'Сравнение объемов заказчиков';
    }
    else if ((selectedOrganization.type === 'company_customer' && activeTab === 'Контракты') || slidePoz === 'supplier') {
        headerTitle = 'Структура объемов исполнителей';
    }
    else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Контракты') || slidePoz === 'customer') {
        headerTitle = 'Структура объемов заказчиков';
    }
    else if (shouldShowChangeButton && treeMapMode === '') {
        headerTitle = "Структура объемов исполнителей";
    }
    else {
        switch (treeMapMode) {
            case 'cust':
                headerTitle = "Структура контрактов по исполнителям";
                break;
            case 'org':
                headerTitle = "Структура контрактов по заказчикам";
                break;
            default:
                headerTitle = activeTab === 'Извещения'
                    ? "Сравнение объемов заказчиков"
                    : "Структура объемов поставщиков";
        }
    }
    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    useEffect(() => {
        let requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            filterOkpd,
            trimCode,
            selectedCountryLine,
            selectedMonth,
            RangeDT,
            activeTab,
            contractTrimCode,
            selectedOkpd,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedKbrSegments,
            selectedZoomableSegment,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (shouldShowChangeButton) {
            const isCustomer = treeMapMode === '' || treeMapMode === 'cust';
            requestData = { ...requestData, isCustomer };
        }

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }
        dispatch(fetchTreeMapData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedZoomableSegment,selectedKbrSegments,filterOkpd,dateChanger,selectedCountryLine,selectedProduct, treeMapMode, activeRegions, pieState, top, trimCode, selectedMonth, relatedINNs, contractTrimCode, selectedOkpd, selectedDonutSegmetsV1, selectedContractMonth, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    useEffect(() => {
        if (loading === 'successful' && width && height && TreeMapData && TreeMapData.nodes) {
            const sortedNodes = [...TreeMapData.nodes].sort((a, b) => b.value - a.value);
            const chunkedData = {
                ...TreeMapData,
                nodes: sortedNodes.slice(0, loadedItemsCount)
            };
            drawChart(chunkedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, TreeMapData, selectedSegments, loadedItemsCount]);

    const hasMoreData = loadedItemsCount < (TreeMapData?.nodes?.length || 0);
    const totalItemsCount = TreeMapData?.nodes?.length || 0;
    const legendTotal = `(${loadedItemsCount}/${totalItemsCount})`
    const remainingItemsCount = totalItemsCount - loadedItemsCount;
    const dynamicValue = Math.min(remainingItemsCount, 24);

    const loadMoreItems = () => {
        dispatch(increaseLoadedItemsCount(dynamicValue));
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const fieldForName = activeTab === 'Извещения' ? 'CustFullName' : 'tooltype';
        const tooltipConfig = tooltipNames.TreeMapNotification.Tabs[activeTab];
        let tooltipText = [
            { label: tooltipConfig.label, value: d.data.extra.find(x => x.label === fieldForName)?.value || 'Н/Д' },
            { label: tooltipConfig.inn, value: d.data.label },
            { label: tooltipConfig.summary, value: formatCurrency(d.data.value) },
            { label: tooltipConfig.count, value: `${d.data.extra.find(x => x.label === 'count')?.value || 0} шт` }
        ];

        let tooltipSize = calculateTooltipSize(tooltipText.map(item => `<div><strong>${item.label}</strong>: ${item.value}</div>`));
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipText
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const handleSegmentClick = (d) => {
        cancelAllPendingRequests();
        onMouseOut();
        const tooltype = d.data.extra?.find(x => x.label === 'tooltype' || x.label === 'CustFullName')?.value;

        const segmentData = {
            label: d.data.label,
            tooltype: tooltype || 'Default Tooltype',
        };

        dispatch(addTreeMapName(headerTitle));
        dispatch(toggleSelectedSegment(segmentData));
    };

    const drawChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);

        const root = d3.hierarchy({children: data.nodes})
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);

        d3.treemap()
            .tile(d3.treemapResquarify)
            .size([width, height])
            .padding(1)
            .round(true)
            (root);

        const color = d3.scaleOrdinal(treeMapColors);
        const leaf = svg.selectAll("g")
            .data(root.leaves())
            .enter().append("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        leaf.append("rect")
            .attr("id", d => d.data.label)
            .attr("width", d => d.x1 - d.x0)
            .attr("height", 0)
            .attr("y", d => d.y1 - d.y0)
            .attr("fill", d => color(d.data.label))
            .attr("stroke", d => selectedTreeMapLabels.includes(d.data.label) ? "var(--tabText-color)" : "none")
            .attr("opacity", d => selectedTreeMapLabels.length === 0 || selectedTreeMapLabels.includes(d.data.label) ? 1 : 0.3)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut)
            .on('click', (event, d) => handleSegmentClick(d))
            .transition()
            .duration(1000)
            .attr("height", d => d.y1 - d.y0)
            .attr("y", d => 0);
        leaf.append("text")
            .attr("x", 4)
            .attr("y", 13)
            .style("fill", "#ffffff")
            .style("font-size", "14px")
            .attr("font-family", "Golos Regular")
            .text(d => d.data.label)
            .each(function(d) {
                const self = d3.select(this);
                let textLength = self.node().getComputedTextLength();
                let text = self.text();
                while (textLength > (d.x1 - d.x0 - 8) && text.length > 0) {
                    text = text.slice(0, -1);
                    self.text(`${text}…`);
                    textLength = self.node().getComputedTextLength();
                }
            });
    };

    return (
        <div className={`${style.container} ${selectedTreeMapLabels.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`}
             style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    legendTotal={legendTotal}
                    activeMode={treeMapMode || 'cust'}
                    onZoomClick={onZoomClick}
                    dynamicValue={dynamicValue}
                    hasMoreData={hasMoreData}
                    loadMoreItems={loadMoreItems}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setTreeMapMode('cust'));
                        } else if (mode === 'supplier?') {
                            dispatch(setTreeMapMode('org'));
                        }
                        else {
                            dispatch(setTreeMapMode(""))
                        }
                    }}
                    diagramType="treeMap"
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styles.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}:</strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default TreeMapDiagramm;
