import React, {useEffect, useMemo, useState} from 'react';
import style from "./ModalContent.module.css";
import xButtonIco from '../../../common/icons/xButtonBlack.svg';
import xButtonIcoDark from '../../../common/icons/xButtonGrey.svg';
import { useDispatch, useSelector } from "react-redux";
import { removeSpecificSlice } from "../../../service/reducers/PieChart/PieChartSegmentSlice.js";
import {clearActiveRegions, toggleRegion} from "../../../service/reducers/GeoChart/GeoChartRegionSlice";
import {
    clearSelectedProduct,
    clearTrimCode, removeProduct,
    setTrimCode
} from "../../../service/reducers/StackedBarChart/ProductCodeChartSlice";
import {
    clearSelectedSegments, removeSpecificSegment, resetTreeMapStore
} from "../../../service/reducers/TreeMapChart/TreeMapChartSegmentSlice";
import {
    clearSelectedMonth, clearSelectedPeriod,
    removeSpecificMonth, resetMonthStore, updateSelectedTimePeriods
} from "../../../service/reducers/PublishChart/BarWithLineChartMonthSlice";
import { resetStore as resetPieSliceStore } from "../../../service/reducers/PieChart/PieChartSegmentSlice.js"
import {removeSegment, resetRoleDonutChart} from "../../../service/reducers/DonutChart/DonutRoleSegmentSlice";
import {resetActivityChart} from "../../../service/reducers/ActivityChart/ActivityChartSegmentSlice";
import {
    removeSpecificMonthV1,
    resetMonthContractStore, updateSelectedTimePeriodsV1
} from "../../../service/reducers/PublishChart/Contracts/ContractBarWithLineChartMonthSlice";
import {resetGeoData} from "../../../service/reducers/GeoChart/GeoChartSlice";
import {
    clearContractTrimCode, clearSelectedContractProduct,
    resetContractOkpd, setContractTrimCode, setSelectedProduct
} from "../../../service/reducers/StackedBarChart/Contracts/ContractOkpdChartSlice";
import {
    clearActiveItemsForDiagram,
    resetLegendClicks,
    setActiveLegendItem
} from "../../../service/reducers/legendItemsClick";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs,
    resetSearchSuppINNINNINNs, resetSelectedComboItem,
    resetSelectedOrganization, resetSelectedSuggestion, resetSelectedValue
} from "../../../service/reducers/organizationSlice";
import {resetOkpdCode} from "../../../service/reducers/selectedComboOkpd";
import {removeSegmentKbr, resetKbrDonutChart} from "../../../service/reducers/KbrChart/DonutChartKbrSegmentSlice";
import geoData from "../../../utils/geoNamesMap.json"
import {cancelAllPendingRequests} from "../../../api/api";
import regionData from "../../../utils/geoNamesMap.json";
import {
    addResetZoomble,
    resetCurrentSegmentName,
    resetSegmentNameStore,
} from "../../../service/reducers/ZoomableChartSegmentSlice";
import {removeBubble, resetBubbleChart} from "../../../service/reducers/BubbleSelectedSegmentSlice";
import {resetStackedShort, setSelectedProductStackedShort} from "../../../service/reducers/StackedLineShort";
import localStorageService from "../../../service/localStorage/localStorageService";

const ModalContent = ({ onClose, areaWord, contentMode }) => {
    const okpdData = localStorageService.getItem('okpdData') || [];
    const buildOkpdMapping = (items, map = {}) => {
        items.forEach(item => {
            map[item.okpd_code] = item.okpd_name;
            if (item.children) {
                buildOkpdMapping(item.children, map);
            }
        });
        return map;
    };

    const okpdNameMapping = useMemo(() => {
        return buildOkpdMapping(okpdData);
        // eslint-disable-next-line
    }, [okpdData]);

    const getDisplayName = (code) => {
        const baseCode = code.endsWith('.') ? code.slice(0, -1) : code;
        const name = okpdNameMapping[baseCode] || "Название не найдено";
        return `${baseCode} - ${name}`;
    };

    const regionNameMapping = useMemo(() => {
        const map = {};
        geoData.forEach(item => {
            map[item.ISO] = item.region;
        });
        return map;
    }, []);

    const dispatch = useDispatch();
    const [cityNamesMap, setCityNamesMap] = useState({});

    useEffect(() => {
        const map = regionData.reduce((acc, { ISO, region }) => {
            acc[ISO] = region;
            return acc;
        }, {});
        setCityNamesMap(map);
    }, []);

    const trimCode = useSelector((state) => state.productCode.trimCode);
    const trimCodes = useMemo(() => {
        return trimCode.map(code => ({
            code: code,
            productName: getDisplayName(code, okpdNameMapping)
        }));
        // eslint-disable-next-line
    }, [trimCode,okpdNameMapping]);

    const trimCodesContractMemo = useSelector(state => state.contractOkpd.trimCode);
    const contractTrimCodes = useMemo(() => {
        return trimCodesContractMemo.map(code => ({
            code: code,
            productName: getDisplayName(code, okpdNameMapping)
        }));
        // eslint-disable-next-line
    }, [trimCodesContractMemo,okpdNameMapping]);

    const selectedProduct = useSelector(state => state.productCode.selectedProduct);

    const selectedOKPD = useMemo(() => {
        return selectedProduct.map(product => ({
            productId: product.productId,
            productName: getDisplayName(product.productId, okpdNameMapping)
        }));
        // eslint-disable-next-line
    }, [selectedProduct, okpdNameMapping]);

    const selectedSlice = useSelector(state => state.pie.selectedSlice);
    const pieName = useSelector(state => state.pie.pieName);

    const selectedRegionsMemo = useSelector(state => state.region.activeRegions);
    const selectedRegions = useMemo(() => {
        return selectedRegionsMemo.map(isoCode => ({
            code: isoCode,
            productName: regionNameMapping[isoCode] || "Название не найдено"
        }));
        // eslint-disable-next-line
    }, [selectedRegionsMemo]);
    const geoName = useSelector((state) => state.region.geoName);

    const okpd1Name = useSelector((state) => state.productCode.stackedName);

    const donutRoles = useSelector(state => state.donutRolesSlice.selectedSegments)
    const roleName = useSelector(state => state.donutRolesSlice.roleChartName)

    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const zoombleName = useSelector(state => state.segmentNameSlice.zoomableName)

    const bubblesName = useSelector(state => state.bubbleSegmentSlice.bubbelChartName)
    const bubblesMemo = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments)
    const selectedBubbles = useMemo(() => {
        return bubblesMemo.map(segment => ({
            label: segment.regNum,
            displayName: `${segment.regNum} - ${segment.productName}`
        }));
        // eslint-disable-next-line
    }, [bubblesMemo]);
    const stackedShortName = useSelector(state => state.ispOkpd.stackedChartShortName)
    const selectedStackedShortSeg = useSelector(state => state.ispOkpd.selectedOkpd)

    const contractOkpdMemo = useSelector(state => state.contractOkpd.selectedOkpd)
    const contractOkpd = useMemo(() => {
        return contractOkpdMemo.map(obj => ({
            productId: obj,
            productName: getDisplayName(obj, okpdNameMapping)
        }));
        // eslint-disable-next-line
    }, [contractOkpdMemo,okpdNameMapping]);

    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const contractName = useSelector((state) => state.contractOkpd.contractOkpdName);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const kbrName = useSelector(state => state.donutKbrSegmentSlice.kbrChartName)

    const selectedSegmentsMemo = useSelector(state => state.treeMapSlice.selectedSegments);
    const selectedSegments = useMemo(() => {
        return selectedSegmentsMemo.map(segment => ({
            label: segment.label,
            displayName: `${segment.label} - ${segment.tooltype}`
        }));
    }, [selectedSegmentsMemo]);

    const treeMapName = useSelector(state => state.treemap.treeMapName)
    const switcher = useSelector(state => state.switcher.activeSwitch);
    const selectedSuggestion = useSelector(state => state.organization.selectedSuggestion);
    const selectedComboItem = useSelector(state => state.organization.selectedComboItem);
    const { selectedMonth,currentActiveButton, selectedTimePeriods,barLineV1Name} = useSelector((state) => state.barLineChartMonth);
    const currentActiveButtonV1 = useSelector(state => state.contractMonth1Slice.currentActiveButton)
    const selectedContractMonthV1 = useSelector(state => state.contractMonth1Slice.selectedContractMonth)
    const selectedTimePeriodsV1 = useSelector(state => state.contractMonth1Slice.selectedTimePeriods)
    const barLineName = useSelector(state => state.contractMonth1Slice.barLineV2Name)

    const [removedItems, setRemovedItems] = useState({
        pie: new Set(),
        region: new Set(),
        trimCode: new Set(),
        contractTrimCode: new Set(),
        segments: new Set(),
        timePeriod: new Set(),
        timePeriodV1: new Set(),
        okpd: new Set(),
        pieRolesCU: new Set(),
        contractOkpd: new Set(),
        kbrSegments: new Set(),
        selectedZoomableSegment: new Set(),
        bubbles: new Set(),
        stackedShort: new Set(),
    });
    const groupByTimePeriod = (selectedMonth, currentActiveButton) => {
        const dateObjects = selectedMonth.map(date => new Date(date));
        dateObjects.sort((a, b) => a - b);

        let groupedDates = [];

        switch (currentActiveButton) {
            case 'month':
                groupedDates = dateObjects.map(date => date.toISOString());
                break;

            case 'quarter':
                groupedDates = dateObjects.reduce((acc, date) => {
                    const quarter = Math.floor((date.getMonth() / 3) + 1);
                    const year = date.getFullYear();
                    const key = `${quarter} Квартал ${year}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(date);
                    return acc;
                }, {});
                break;

            case 'half-year':
                groupedDates = dateObjects.reduce((acc, date) => {
                    const half = date.getMonth() < 6 ? "Первая половина" : "Вторая половина";
                    const year = date.getFullYear();
                    const key = `${half} ${year}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(date);
                    return acc;
                }, {});
                break;

            case 'year':
                groupedDates = dateObjects.reduce((acc, date) => {
                    const year = date.getFullYear();
                    const key = `${year} год`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(date);
                    return acc;
                }, {});
                break;

            default:
                console.error('Invalid time period');
                return;
        }

        return groupedDates;
    };

    const groupedTimePeriod = groupByTimePeriod(selectedMonth, currentActiveButton);
    const formatDisplayDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleString('ru', { month: 'long', year: 'numeric' });
    };

    const displayItems = currentActiveButton === 'month'
        ? selectedMonth.map(date => formatDisplayDate(date))
        : Object.keys(groupedTimePeriod).map(period => period);

    const groupedTimePeriodV1 = groupByTimePeriod(selectedContractMonthV1, currentActiveButtonV1);

    const displayItemsV1 = currentActiveButtonV1 === 'month'
        ? selectedContractMonthV1.map(date => formatDisplayDate(date))
        : Object.keys(groupedTimePeriodV1).map(period => period);

    const [applyButtonVisible, setApplyButtonVisible] = useState(false);

    const graphs = [
        { title: pieName, items: selectedSlice, type: 'pie' },
        { title: kbrName, items: selectedKbrSegments, type: 'kbrSegments' },
        { title: geoName, items: selectedRegions, type: 'region' },
        { title: roleName, items: donutRoles, type: 'pieRolesCU' },
        ...(trimCode && trimCode.length > 0 ? [{ title: `${okpd1Name} (Уровень)`, items: trimCodes, type: 'trimCode' }] : []),
        ...(contractTrimCode && contractTrimCode.length > 0 ? [{ title: `${contractName} (Уровень)`, items: contractTrimCodes, type: 'contractTrimCode' }] : []),
        { title: treeMapName, items: selectedSegments, type: 'segments' },
        { title: contractName, items: contractOkpd, type: 'contractOkpd' },
        { title: bubblesName, items: selectedBubbles, type: 'bubbles' },
        { title: stackedShortName, items: selectedStackedShortSeg, type: 'stackedShort' },
        { title: zoombleName, items: selectedZoomableSegment, type: 'selectedZoomableSegment' },
        {
            title: barLineV1Name,
            items: displayItems,
            type: 'timePeriod'
        },
        {
            title: barLineName,
            items: displayItemsV1,
            type: 'timePeriodV1'
        },
        {
            title: `${okpd1Name} (Сегменты)`,
            items: selectedOKPD,
            type: 'okpd'
        },
    ];

    const handleRemoveAll = () => {
        cancelAllPendingRequests()
        dispatch(resetLegendClicks())
        dispatch(resetGeoData())
        dispatch(clearSelectedContractProduct())
        dispatch(clearContractTrimCode())
        dispatch(resetContractOkpd())
        dispatch(resetPieSliceStore());
        dispatch(resetTreeMapStore());
        dispatch(resetMonthStore());
        dispatch(resetMonthContractStore());
        dispatch(resetRoleDonutChart());
        dispatch(resetActivityChart())
        dispatch(clearActiveRegions());
        dispatch(clearSelectedProduct())
        dispatch(clearSelectedSegments());
        dispatch(clearSelectedMonth());
        dispatch(resetKbrDonutChart());
        dispatch(clearSelectedPeriod());
        dispatch(clearTrimCode());
        dispatch(resetSegmentNameStore());
        dispatch(resetBubbleChart());
        dispatch(resetStackedShort());
        onClose();
    };

    const handleClearSearch = () => {
        cancelAllPendingRequests()
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetSelectedOrganization());
        dispatch(resetRelatedINNs());
        dispatch(resetSelectedComboItem());
        dispatch(resetSelectedValue())
        dispatch(resetOkpdCode());
        dispatch(resetSelectedSuggestion());
        dispatch(clearContractTrimCode())
        onClose();
    }
    const handleRemoveItem = (item, type) => {
        const newSet = new Set(removedItems[type]);
        let itemKey = (type === 'segments' || type === 'bubbles' ? item.label : item.productId || item.code || item);
        newSet.add(itemKey);
        setRemovedItems({ ...removedItems, [type]: newSet });
        setApplyButtonVisible(true);
    };

    const handleRestoreItem = (item, type) => {
        const newSet = new Set(removedItems[type]);
        let itemKey = (type === 'segments' || type === 'bubbles' ? item.label : item.productId || item.code || item);
        newSet.delete(itemKey);
        setRemovedItems({ ...removedItems, [type]: newSet });
        setApplyButtonVisible([...newSet].length > 0);
    };

    const toOriginalTimeZoneString = (dateStringWithTimeZone) => {
        const date = new Date(dateStringWithTimeZone);
        const offset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offset);
        return date.toISOString().replace('.000Z', `${offset >= 0 ? '-' : '+'}${String(Math.abs(offset) / 60).padStart(2, '0')}:00`);
    };

    const randomNumber = Math.random();
    const handleApplyAll = () => {
        cancelAllPendingRequests()
        removedItems.pie.forEach(item => {
            dispatch(removeSpecificSlice(item));
            dispatch(setActiveLegendItem({ diagramId: pieName, activeItem: item }));
        });
        removedItems.contractOkpd.forEach(productId => {
            dispatch(setSelectedProduct(productId));
            dispatch(setActiveLegendItem({ diagramId: contractName, activeItem: productId }));
        });
        removedItems.pieRolesCU.forEach(item => {
            dispatch(removeSegment(item));
            dispatch(setActiveLegendItem({ diagramId: roleName, activeItem: item }));
        });
        removedItems.kbrSegments.forEach(item => {
            dispatch(removeSegmentKbr(item));
            dispatch(setActiveLegendItem({ diagramId: kbrName, activeItem: item }));
        });
        removedItems.okpd.forEach(productId => {
            dispatch(removeProduct({productId}));
        });
        removedItems.selectedZoomableSegment.forEach(label => {
            dispatch(resetCurrentSegmentName(label))
            dispatch(addResetZoomble(randomNumber));
        });
        if (removedItems.region && removedItems.region.size > 0) {
            removedItems.region.forEach(regionCode => {
                dispatch(toggleRegion(regionCode));
                const regionName = cityNamesMap[regionCode] || regionCode;
                dispatch(setActiveLegendItem({ diagramId: geoName, activeItem: regionName }));
            });
        }
        if (removedItems.contractTrimCode && removedItems.contractTrimCode.size > 0) {
            let contractTrimCode = Array.from(removedItems.contractTrimCode).pop();

            if (Array.isArray(contractTrimCode)) {
                contractTrimCode = contractTrimCode[0];
            }
            if (contractTrimCode) {
                const sections = contractTrimCode.split('.');
                if (sections.length > 2) {
                    sections.splice(-2, 2);
                    const newContractTrimCode = [sections.join('.') + '.'];
                    dispatch(setContractTrimCode(newContractTrimCode));
                } else {
                    dispatch(clearContractTrimCode());
                    dispatch(clearSelectedContractProduct());
                    dispatch(clearActiveItemsForDiagram({ diagramId: contractName }));
                }
            }
        }
        if (removedItems.trimCode && removedItems.trimCode.size > 0) {
            let currentTrimCode = Array.from(removedItems.trimCode).pop();

            if (Array.isArray(currentTrimCode)) {
                currentTrimCode = currentTrimCode[0];
            }

            if (currentTrimCode) {
                const sections = currentTrimCode.split('.');
                if (sections.length > 2) {
                    sections.splice(-2, 2);
                    const newTrimCode = [sections.join('.') + '.'];
                    dispatch(setTrimCode(newTrimCode));
                } else {
                    dispatch(clearTrimCode());
                    dispatch(clearSelectedProduct());
                }
            }
        }
        if (removedItems.segments && removedItems.segments.size > 0) {
            removedItems.segments.forEach(segment => {
                dispatch(removeSpecificSegment(segment));
            });
        }
        if (removedItems.bubbles && removedItems.bubbles.size > 0) {
            removedItems.bubbles.forEach(segment => {
                dispatch(removeBubble(segment));
            });
        }
        if (removedItems.stackedShort && removedItems.stackedShort.size > 0) {
            removedItems.stackedShort.forEach(segment => {
                dispatch(setSelectedProductStackedShort(segment));
                dispatch(setActiveLegendItem({ diagramId: stackedShortName, activeItem: segment }));
            });
        }
        if (removedItems.timePeriod && removedItems.timePeriod.size > 0) {
            const allMonthsToDelete = [];
            const timePeriodsToDelete = [];

            removedItems.timePeriod.forEach(timePeriod => {
                let monthsToDelete = [];
                if (currentActiveButton === 'month') {
                    monthsToDelete = selectedMonth.filter(month => formatDisplayDate(month) === timePeriod)
                        .map(date => toOriginalTimeZoneString(date));
                } else {
                    monthsToDelete = groupedTimePeriod[timePeriod]?.map(date => toOriginalTimeZoneString(date)) || [];
                    timePeriodsToDelete.push(timePeriod);
                }
                allMonthsToDelete.push(...monthsToDelete);
            });

            if (allMonthsToDelete.length > 0) {
                dispatch(removeSpecificMonth(allMonthsToDelete));
            }

            const newSelectedTimePeriods = [...selectedTimePeriods];
            newSelectedTimePeriods.splice(0, removedItems.timePeriod.size);

            dispatch(updateSelectedTimePeriods(newSelectedTimePeriods));
        }

        if (removedItems.timePeriodV1 && removedItems.timePeriodV1.size > 0) {
            const allMonthsToDelete = [];
            const timePeriodsToDelete = [];

            removedItems.timePeriodV1.forEach(timePeriod => {
                let monthsToDelete = [];
                if (currentActiveButtonV1 === 'month') {
                    monthsToDelete = selectedContractMonthV1.filter(month => formatDisplayDate(month) === timePeriod)
                        .map(date => toOriginalTimeZoneString(date));
                } else {
                    monthsToDelete = groupedTimePeriodV1[timePeriod]?.map(date => toOriginalTimeZoneString(date)) || [];
                    timePeriodsToDelete.push(timePeriod);
                }
                allMonthsToDelete.push(...monthsToDelete);
            });

            if (allMonthsToDelete.length > 0) {
                dispatch(removeSpecificMonthV1(allMonthsToDelete));
            }

            const newSelectedTimePeriods = [...selectedTimePeriodsV1];
            newSelectedTimePeriods.splice(0, removedItems.timePeriodV1.size);

            dispatch(updateSelectedTimePeriodsV1(newSelectedTimePeriods));
        }

        setRemovedItems({
            pie: new Set(),
            region: new Set(),
            trimCode: new Set(),
            contractTrimCode: new Set(),
            segments: new Set(),
            timePeriod: new Set(),
            timePeriodV1: new Set(),
            okpd: new Set(),
            pieRolesCU: new Set(),
            contractOkpd: new Set(),
            kbrSegments: new Set(),
            selectedZoomableSegment: new Set(),
            bubbles: new Set(),
            stackedShort: new Set(),
        });
        setApplyButtonVisible(false);
        onClose();
    };

    return (
        <div className={style.contentContainer}>
            <div className={style.contentHeader}>
                <div>
                    {contentMode === "default" ? (
                        `Выбрано ${graphs.reduce((count, graph) => count + graph.items.length, 0)} ${areaWord}`
                    ) : contentMode === "combo" ? (
                        <>
                            Название группы ОКПД/Компаний<br />
                            {selectedComboItem?.label}
                        </>
                    ) : (
                        "Информация о компании"
                    )}
                </div>
                <div className={style.closeXIcon} onClick={onClose}>
                    {switcher === 'sun' ?
                        <img src={xButtonIco} alt="sunIcon" /> :
                        <img src={xButtonIcoDark} alt="moonIcon" />
                    }
                </div>
            </div>
           <div className={style.list}>
    {contentMode === "default" ? (
        graphs.map((graph, graphIndex) => (
            graph.items.length > 0 && (
                <div key={graphIndex}>
                    <div className={style.ulTitle}>График: {graph.title}</div>
                    <ol>
                        {graph.items.map((item, itemIndex) => {
                            const id = graph.type === 'segments' || graph.type === 'bubbles' ? item.label : item.productId || item.code || item;
                            const isRemoved = removedItems[graph.type].has(id);

                            return (
                                <li key={itemIndex} className={style.listItem}>
                            <span className={style.itemText}
                                  style={{ color: isRemoved ? "#8D96B2" : "inherit" }}>
                                {itemIndex + 1}. {item.productName || item.displayName || item}
                            </span>
                                    <div className={style.removeItem}
                                         onClick={() => {
                                             isRemoved
                                                 ? handleRestoreItem(item, graph.type)
                                                 : handleRemoveItem(item, graph.type);
                                         }}>
                                        {isRemoved ? "Восстановить" : "Убрать"}
                                    </div>
                                </li>
                            );
                        })}
                    </ol>
                </div>
            )
        ))
    ) : (
        <>
            <div className={style.ulTitle}>{selectedSuggestion.full_name}</div>
            <div className={style.ulTitle}>{selectedSuggestion.inn}</div>
        </>
    )}
               {
                   contentMode === "combo" && (
                       <div>
                           {selectedComboItem && (
                               <>
                                   <div className={style.ulTitle}>
                                       {selectedComboItem?.type === 'company_customer'
                                           ? 'Компании заказчики'
                                           : selectedComboItem?.type === 'company_suppliers'
                                               ? 'Компании исполнители'
                                               : selectedComboItem?.companies?.length > 0
                                                   ? 'Компании'
                                                   : selectedComboItem?.okpds?.length > 0
                                                       ? 'ОКПД'
                                                       : null}
                                   </div>
                                   <div>
                                       {selectedComboItem?.companies?.map((company, index) => (
                                           <div key={index} className={style.listItem}>
                                            <span className={style.itemText}>
                                              {company.full_name}, ИНН {company.inn}
                                            </span>
                                           </div>
                                       ))}
                                       {selectedComboItem?.okpds?.map((okpd, index) => (
                                           <div key={index} className={style.listItem}>
                                            <span className={style.itemText}>
                                              {okpd.okpd_name} Код {okpd.okpd_code}
                                            </span>
                                           </div>
                                       ))}
                                   </div>
                               </>
                           )}
                       </div>
                   )
               }
           </div>
            <div className={style.btnContainter}>
                {applyButtonVisible ? (
                    <div className={style.removeAllButtonContainer} onClick={handleApplyAll}>
                        Применить
                    </div>
                ) : (
                    <div className={style.hidden}>
                        Применить
                    </div>
                )}
                {contentMode === "default" ? (
                    <div className={style.removeAllButtonContainer} onClick={handleRemoveAll}>
                        Убрать все: {graphs.reduce((count, graph) => count + graph.items.length, 0)}
                    </div>
                ) : (
                    <div className={style.removeAllButtonContainer} onClick={handleClearSearch}>
                        Сбросить
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalContent;
