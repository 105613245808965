import React, {useEffect, useMemo} from 'react';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../TestPages/Spinner";
import {fetchSummaryData} from "../../../service/reducers/Summary/SummarySlice";
import styles from "./Summary.module.css"
import {formatShortCurrency, getCurrencyUnit} from "../../../utils/rubbleFunc";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
const Summary = ({ zoomedDiagram }) => {
    const dispatch = useDispatch();
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const {SummaryData , loading } = useSelector((state) => state.summaryData);
    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const top = useSelector((state) => state.activitySlice);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);

    useEffect(() => {
        let requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            trimCode,
            filterOkpd,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedKbrSegments,
            RangeDT,
            selectedCountryLine,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };
        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }
        else if (slidePoz === 'customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')){
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        }
        else if (slidePoz === 'supplier' && (activeTab === 'Контракты' || activeTab === 'Исполнение')){
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }
        dispatch(fetchSummaryData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,selectedKbrSegments,filterOkpd,dateChanger, selectedProduct, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedDonutSegmetsV1, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    if (loading === 'pending' || loading === 'failed' || !SummaryData || !SummaryData.nodes) {
        return <Spinner />;
    }

    const getActiveTabLabel = (activeTab) => {
        switch (activeTab) {
            case 'Извещения':
                return 'Сумма НМЦК закупок';
            case 'Контракты':
                return 'Сумма контрактов';
            case 'Исполнение':
                return 'Исполнено';
            default:
                return 'Тариф';
        }
    };

    const getExcludedLabels = () => {
        if (activeTab === 'Контракты') {
            if (!selectedOrganization.type && !slidePoz) {
                return ['Кол-во заказчиков'];
            }
            if ((selectedOrganization.type === 'company_customer' && activeTab === 'Контракты') || slidePoz === 'supplier') {
                return ['Кол-во заказчиков'];
            }
            else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Контракты') || slidePoz === 'customer') {
                return ['Кол-во исполнителей'];
            }
        }
        if (activeTab === 'Исполнение') {
            if (!selectedOrganization.type && !slidePoz) {
                return ['Штрафов заказчиков', 'Пени заказчиков'];
            }
            if ((selectedOrganization.type === 'company_customer' && activeTab === 'Исполнение') || slidePoz === 'supplier') {
                return ['Штрафов заказчиков', 'Пени заказчиков'];
            }
            else if ((selectedOrganization.type === 'company_suppliers' && activeTab === 'Исполнение') || slidePoz === 'customer') {
                return ['Штрафов исполнителя', 'Пени исполнителя'];
            }
        }

        return [];
    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram small-chart`} style={zoomedDiagram ? { height: "600px" } : {}}>
            {(loading === 'pending' || isLoadingMenu) ? (
                <Spinner />
            ) : loading === 'successful' ? (
                <div className={styles.content}>
                    <div className={styles.total}>
                        <div>
                            {getActiveTabLabel(activeTab)}, {getCurrencyUnit(SummaryData.total)}
                        </div>
                        <div className={styles.totalPrice}>
                            {formatShortCurrency(SummaryData.total )}
                        </div>
                    </div>
                    <div className={styles.items}>
                        {SummaryData.nodes
                            .filter(node => !getExcludedLabels().includes(node.label))
                            .map((node, index) => {
                                const isPayment = node.extra && node.extra.some(item => item.label === 'payment' && item.value);
                                const displayValue = isPayment
                                    ? `${formatShortCurrency(node.value)} ${getCurrencyUnit(node.value)}`
                                    : node.value.toLocaleString();
                                //const displayLabel = isPayment ? `${node.label}, ${getCurrencyUnit(SummaryData.total)}` : node.label;
                                return (
                                    <div key={index} className={styles.item}>
                                        <span className={styles.label}>{node.label}</span>
                                        <span className={styles.value}>{displayValue}</span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Summary;
