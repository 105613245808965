import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPrivileges } from "../service/reducers/PrivelegiesSlice";
import { getDateForPickers } from "../api/api";
import axios from "axios";

const AuthCheck = ({ setIsAuthChecked }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [firstEntry, setFirstEntry] = useState(true);
    const activeTab = useSelector((state) => state.tabs.activeTab);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const tokenFromQuery = queryParams.get('token');
                const token = tokenFromQuery || localStorage.getItem('token');

                if (tokenFromQuery) {
                    localStorage.setItem('token', tokenFromQuery);
                    console.log('Token from query params updated');
                }

                if (token) {
                    await dispatch(fetchUserPrivileges(token)).unwrap();
                    const dateForPickersResponse = await getDateForPickers();
                    const sortedDateForPickersResponse = dateForPickersResponse.sort((a, b) => {
                        if (a.type === 'start_date' && b.type === 'end_date') {
                            return -1;
                        } else if (a.type === 'end_date' && b.type === 'start_date') {
                            return 1;
                        }
                        return 0;
                    });
                    localStorage.setItem('dateForPickers', JSON.stringify(sortedDateForPickersResponse));
                } else {
                    const response = await axios.post('https://test-auth-goszakupki.agatha.pw/login', { username: 'ido.nezmir@gmail.com', password: '64db4c30-7e58-4b41-8856-369147afe57b' });
                    const newToken = response.data.token;
                    localStorage.setItem('token', newToken);
                    await dispatch(fetchUserPrivileges(newToken)).unwrap();
                    const dateForPickersResponse = await getDateForPickers();
                    const sortedDateForPickersResponse = dateForPickersResponse.sort((a, b) => {
                        if (a.type === 'start_date' && b.type === 'end_date') {
                            return -1;
                        } else if (a.type === 'end_date' && b.type === 'start_date') {
                            return 1;
                        }
                        return 0;
                    });
                    localStorage.setItem('dateForPickers', JSON.stringify(sortedDateForPickersResponse));
                }

                if (location.pathname === '/' || location.pathname === '/content' || location.pathname === '/content/dashBoard') {
                    navigate('/content/dashboard');
                }
            } catch (error) {
                navigate('/');
                setFirstEntry(false);
            } finally {
                setTimeout(() => {
                    setIsAuthChecked(true);
                }, 1500);
            }
        };

        checkAuthentication();
    }, [navigate, dispatch, setIsAuthChecked, location.pathname, firstEntry, activeTab, location.search]);

    return null;
};

export default AuthCheck;
