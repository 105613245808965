import axios from 'axios';
import localStorageService from "../service/localStorage/localStorageService";

//Change url here
const BASE_URL = 'https://backend-public.tender.agatha-hub.ru';
//http://217.175.44.39:8080/notifications
const GRAPH_FIRSTPAGE_URL = '/notifications/';
const GRAPH_SECONDPAGE_URL = '/contract/v1/';
const GRAPH_THIRD_URL = '/procedure/v1/';

const tabConfig = {
    'Извещения': {
        url: GRAPH_FIRSTPAGE_URL,
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchOrgINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'PlacingWayName',
        monthParam: 'StartDT',
        innSegmentParam: 'CustINN',
        filterOkpd: 'filterOkpd',
        activeRegionsParam: 'CustISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'ResponsibleRole',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        purchaseCode: 'PurchaseCode',
        selectedZoomableSegment: 'ZoomLvl1',
        endpoints: {
            pieChart: 'graph/v2/graph_summary',
            barWithLine: 'graph/v2/graph_publications_month',
            treeMap: 'graph/v2/graph_treemap',
            geo: 'graph/v2/graph_geo',
            okpd1: 'graph/v2/graph_okpd',
            donutKbr: 'graph/v2/graph_kbr_code',
            activity: 'graph/v2/graph_advantages',
            donutRoleLName: 'graph/v2/graph_responsible_role',
            soloLine: 'graph/v2/graph_payments_month',
            manyLines: 'graph/v2/graph_finance_month',
            summary: 'statistic',
            infoStatistics: 'numbers',
            sunBurst: 'graph/v2/zoomable',
            placingMonth: 'graph/v2/graph_placingwayname_month',
        }
    },
    'Контракты': {
        url: GRAPH_SECONDPAGE_URL,
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchCustINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'BudgetLevelName',
        monthParam: 'ContractSignDate',
        monthParamV1: 'PaymentDate',
        innSegmentParam: 'SupINN',
        filterOkpd: 'filterOkpd',
        activeRegionsParam: 'SupISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'SupLegalName',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        purchaseCode: 'Status',
        selectedZoomableSegment: 'ZoomLvl1',
        endpoints: {
            pieChart: 'graph/budget_level',
            barWithLine: 'graph/sign_date_month',
            treeMap: 'graph/tree_map',
            geo: 'graph/geo',
            okpd: 'graph/okpd',
            donutKbr: 'graph/status',
            contractBarWithLine: 'graph/payment_month',
            activity: 'graph/contract_placing',
            donutRoleLName: 'graph/legal_name',
            summary: 'statistic',
            infoStatistics: 'numbers',
            sunBurst: 'graph/zoomable'
        }
    },
    'Исполнение': {
        url: GRAPH_THIRD_URL,
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchCustINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'Status',
        filterOkpd: 'filterOkpd',
        monthParam: 'DocumentDate',
        activeRegionsParam: 'SupISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'SupLegalName',
        countryLine: 'ProductOrigincountryFullName',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        selectedZoomableSegment: 'ZoomLvl1',
        procedureRegNum: 'ProcedureRegNum',
        endpoints: {
            pieChart: 'graph/status',
            barWithLine: 'graph/document_date_month',
            treeMap: 'graph/tree_map',
            geo: 'graph/geo',
            okpd: 'graph/okpd',
            donutKbr: 'graph/contract_code',
            activity: 'graph/contract_placing',
            donutRoleLName: 'graph/legal_form',
            summary: 'statistic',
            indicator: 'graph/planned',
            sunKey: 'graph/sankey',
            scatter: 'graph/scatter_product',
            sunBurst: 'graph/penalty_lvl',
            okpd1: 'graph/country',
            infoStatistics: 'numbers'
        }
    }
}

const createPayload = (options, tabConfig, activeTab) => {
    const {monthParamV1,procedureRegNum,selectedZoomableSegment,purchaseCode, isCustomer, searchSuppINNINNINNs, searchOrgINNINNs, groupParam, pieParam, monthParam, innSegmentParam, donutRoleV1Param, activeRegionsParam, countryLine, filterOkpd, RangeDT } = tabConfig[activeTab];
    const isNewOKPDCodeEmpty = options.newOKPDCode === undefined || (Array.isArray(options.newOKPDCode) && options.newOKPDCode.length === 0);
    const isTrimCodeEmpty = Array.isArray(options.trimCode) && options.trimCode.length === 0;
    const payload = {
        NewOKPDCode: isNewOKPDCodeEmpty ? options.selectedOkpd : options.newOKPDCode,
        ...options.topBody,
        TrimCode: isTrimCodeEmpty ? options.contractTrimCode : options.trimCode,
    };
    payload[selectedZoomableSegment] = options.selectedZoomableSegment;
    payload[procedureRegNum] = options.regNumArray;
    payload[purchaseCode] = options.selectedKbrSegments;
    payload[RangeDT] = options.RangeDT;
    payload[countryLine] = options.selectedCountryLine
    payload[filterOkpd] = options.filterOkpd;
    payload[groupParam] = options.relatedINNs;
    payload[searchOrgINNINNs] = options.searchOrgINNINNs;
    payload[searchSuppINNINNINNs] = options.searchSuppINNINNINNs;
    payload[pieParam] = options.pieState;
    if (options.selectedMonth && options.selectedMonth.length > 0) {
        payload[monthParam] = options.selectedMonth;
    } else {
        payload[monthParamV1] = options.selectedContractMonth;
    }
    payload[isCustomer] = options.isCustomer;
    if (options.searchOrgINNINNs > 0) {
        payload["SupINN"] = options.selectedTreeMapLabels;
        payload["SupISO"] = options.activeRegions;
        payload[donutRoleV1Param] = options.selectedDonutSegmetsV1;
    }
    else if (options.searchSuppINNINNINNs > 0) {
        payload["CustINN"] = options.selectedTreeMapLabels;
        payload["CustISO"] = options.activeRegions;
        payload["CustLegalName"] = options.selectedDonutSegmetsV1;
    }
    else if (options.selectedCustSegments && options.selectedCustSegments.length > 0) {
        payload["CustINN"] = options.selectedCustSegments;
    }
    else if (options.activeRegionsCust && options.activeRegionsCust.length > 0) {
        payload["CustISO"] = options.activeRegions;
    }
    else if (options.selectedDonutCust && options.selectedDonutCust.length > 0) {
        payload["CustLegalName"] = options.selectedDonutSegmetsV1;
    }
    else {
        payload[innSegmentParam] = options.selectedTreeMapLabels;
        payload[activeRegionsParam] = options.activeRegions;
        payload[donutRoleV1Param] = options.selectedDonutSegmetsV1;
    }
    return payload;
};

let cancelSources = [];

const cleanPayload = (payload) => {
    const cleanedPayload = {};
    Object.keys(payload).forEach(key => {
        if (payload[key] !== undefined && payload[key] !== null && payload[key] !== '' && !(Array.isArray(payload[key]) && payload[key].length === 0)) {
            cleanedPayload[key] = payload[key];
        }
    });
    return cleanedPayload;
};

const fetchData = (endpoint, options) => {
    const { url } = tabConfig[options.activeTab];
    const payload = createPayload(options, tabConfig, options.activeTab);
    const cleanedPayload = cleanPayload(payload);
    const shouldAddLimitSan = options.activeTab === 'Исполнение' && endpoint === tabConfig['Исполнение'].endpoints.sunKey;
    const shouldAddLimitScatter = endpoint === tabConfig['Исполнение'].endpoints.scatter;
    let limit = null;

    if (shouldAddLimitSan) {
        limit = options.currentLimit || 15;
    } else if (shouldAddLimitScatter) {
        limit = options.currentLimitBubble || 300;
    }

    const source = axios.CancelToken.source();
    cancelSources.push(source);

    return axios.post(
        `${BASE_URL}${url}${endpoint}`,
        cleanedPayload,
        {
            cancelToken: source.token,
            withCredentials: true,
            ...(limit ? { params: { limit } } : {})
        }
    ).catch(error => {
        if (error.response && error.response.status === 401) {
            return Promise.reject(new Error('unauthorized'));
        }
        return Promise.reject(error);
    });
};

export const cancelAllPendingRequests = () => {
    cancelSources.forEach(source => source.cancel('Operation canceled by user.'));
    cancelSources = [];
};

//role donut
export const fetchRespRole = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.donutRoleLName;
    return fetchData(endpoint, options);
};

export const fetInfoStatistic = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.infoStatistics;
    return fetchData(endpoint, options);
}

//OKPD
export const fetchOkpd = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const okpdEndPoint = endpoints.okpd1;
    return fetchData(okpdEndPoint, options);
};

export const fetchPlacingMonth = (options) => {
    const { endpoints } = tabConfig['Извещения'];
    const placingMonthEndPoint = endpoints.placingMonth;
    return fetchData(placingMonthEndPoint, options);
};

export const fetchIndicator = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const indicatorEndpoint = endpoints.indicator;
    return fetchData(indicatorEndpoint, { ...options, activeTab: 'Исполнение' });
};
export const fetchSunBurst = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const sunBurstEndpoint = endpoints.sunBurst;
    return fetchData(sunBurstEndpoint, options);
};

export const fetchSunKey = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const indicatorEndpoint = endpoints.sunKey;
    return fetchData(indicatorEndpoint, { ...options, activeTab: 'Исполнение' });
};

export const fetchScatter = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const scatterEndpoint = endpoints.scatter;
    return fetchData(scatterEndpoint, { ...options, activeTab: 'Исполнение' });
};

export const fetchContractOkpd = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const contractOkpdEndPoint = endpoints.okpd;
    return fetchData(contractOkpdEndPoint, options);
};
//pie chart
export const fetchGraphSummary = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const summaryEndPoint = endpoints.pieChart;
    return fetchData(summaryEndPoint, options);
};

//treemap chart
export const fetchTreeMap = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const treeMapEndpoint = endpoints.treeMap;
    return fetchData(treeMapEndpoint, options);
};

export const fetchActivity = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const activityEndpoint = endpoints.activity;
    const okpdCode = options.activeTab === 'Извещения' ? options.newOKPDCode : options.selectedOkpd;
    const requestTrimCode = options.activeTab === 'Извещения' ? options.trimCode : options.contractTrimCode;
    const payload = createPayload(options, tabConfig, options.activeTab);
    payload.NewOKPDCode = okpdCode;
    payload.TrimCode = requestTrimCode;
    return fetchData(activityEndpoint, {...options, ...payload});
};

//bar with line
export const fetchBarLine = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const barWithLineEndpoint = endpoints.barWithLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(barWithLineEndpoint, { ...options, ...payload });
};

//contract bar with line
export const fetchContractBarLine = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const contractBarWithLineEndpoint = endpoints.contractBarWithLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(contractBarWithLineEndpoint, { ...options, ...payload });
};

//statistics
export const fetchSummary = (options) => {
    const endpoint = 'statistic';
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//kbr donut chart
export const fetchKBR = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.donutKbr;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//geoChart
export const fetchGeo = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.geo;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//solo line chart
export const fetchSoloLine = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.soloLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//many line chart
export const fetchManyLine = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.manyLines;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

export const fetchColors = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/directory/color`);
        const colors = response.data.result;
        localStorageService.setItem('colors', colors);

        return colors;
    } catch (error) {
        console.error("Error fetching colors:", error);
        throw error;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/personal-area/users` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching colors:", error);
        throw error;
    }
};

export const fetchMe = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/personal-area/me` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching colors:", error);
        throw error;
    }
};

export const fetchOrganization = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/personal-area/organization` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching colors:", error);
        throw error;
    }
};

export const searchCompanies = async (searchTerm) => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${BASE_URL}/personal-area/search/company`, {
            cancelToken: source.token,
            params: { search: searchTerm },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при поиске:', error);
        throw error;
    }
};

export const submitStepOne = async (groupData) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/personal-area/groups/`,
            groupData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const deleteGroup = async (group) => {
    try {
        const response = await axios.delete(`${BASE_URL}/personal-area/groups/`, {
            data: group,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addUser = async (userData) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/groups/user`,
            userData, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addOkpd = async (okpdData) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/groups/okpd`,
            okpdData, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const deleteOkpd = async (okpdData) => {
    try {
        const response = await axios.delete(`${BASE_URL}/personal-area/groups/okpd`, {
            data: okpdData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userData) => {
    try {
        const response = await axios.delete(`${BASE_URL}/personal-area/groups/user`, {
            data: userData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении пользователя из группы:', error);
        throw error;
    }
};

export const addGroup = async (groupData) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/groups/inn`,
            groupData, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const deleteInGroup = async (groupData) => {
    try {
        const response = await axios.delete(`${BASE_URL}/personal-area/groups/inn`, {
            data: groupData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении группы:', error);
        throw error;
    }
};

export const addAccount = async (accountData) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/users`,
            accountData, { withCredentials: true });
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const deleteAccount = async (userId) => {
    try {
        const url = `${BASE_URL}/personal-area/users?user_id=${userId}`;
        const response = await axios.delete(url, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении аккаунта', error);
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        const response = await axios.post(
            'https://test-auth-goszakupki.agatha.pw/reset_password',
            { email },
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};


export const getOkpd2 = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/personal-area/search/okpd`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при поиске:', error);
        throw error;
    }
};

export const addOkpdNotInGroup = async (okpd) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/okpd`,
            okpd, { withCredentials: true });
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const getUserOkpd = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${BASE_URL}/personal-area/okpd`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при поиске:', error);
        throw error;
    }
};

export const deleteUserOkpd = async (okpdCode) => {
    try {
        const url = `${BASE_URL}/personal-area/okpd`;
        const response = await axios.delete(url, {
            data: { okpd: okpdCode },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении ОКПД', error);
        throw error;
    }
};

export const deleteSoloCompany = async (inn) => {
    try {
        const url = `${BASE_URL}/personal-area/company`;
        const response = await axios.delete(url, {
            data: { inn: inn },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении ОКПД', error);
        throw error;
    }
};

export const addCompanyNotInGroup = async (inn) => {
    try {
        const response = await axios.post(`${BASE_URL}/personal-area/company`,
            { inn: inn },
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const getUserCompany = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${BASE_URL}/personal-area/company`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при поиске:', error);
        throw error;
    }
};


export const getUserGroups = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${BASE_URL}/personal-area/me_group`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении данных о группах:', error);
    }
};

export const getDateForPickers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/personal-area/setting`, {
            withCredentials: true
        });
        return response.data.result;
    } catch (error) {
        console.error('Ошибка при получении данных о дате:', error);
    }
};

export const addRangeDate = async (startDate, endDate) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/personal-area/setting`,
            [
                { type: 'start_date', body: startDate },
                { type: 'end_date', body: endDate }
            ],
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const testSearch = async (queryParams) => {
    try {
        const response = await axios.post(
            'https://site-goszakupki-backend.agatha.pw/api/purchase',
            queryParams,
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};
