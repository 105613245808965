import styles from './HeaderContent.module.css';
import {useEffect, useState} from 'react';
import PlusIcon from '../../../common/icons/Plusgrey.svg';
import React, { useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActiveTab} from "../../../service/reducers/DiagrammConfig/TabSlice";
import {addRangeDate, cancelAllPendingRequests} from "../../../api/api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CustomDateButton} from "../../../components/CustomDateButton/CustomDateButton";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { formatISO, addHours } from 'date-fns';
import {updateLocalStorageDates} from "../../../utils/updateLocalStorageDates";
import {setSelectedDate} from "../../../service/reducers/ChangeGlobalDatesSlice";
import FilterIconWithBadge from "../../../components/FilterComponent/FilterBadge";
import {useClearAll} from "../../../hook/useClearAll";
import calendarIcon from '../../../common/icons/calendarIcon.svg';
import {CircleWithLabel} from "../../TestPages/TestPagePayment/CircleWithLabel/CircleWithLabel";

registerLocale('ru', ru);
setDefaultLocale('ru');
const HeadDashContent = () => {
    const [activeItem, setActiveItem] = useState(0);
    const [sliderStyle, setSliderStyle] = useState({});
    const itemsRef = useRef([]);
    const leftPartRef = useRef(null);
    const dispatch = useDispatch();
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const filterButtonRef = useRef(null);
    const filterComponentRef = useRef(null);

    const [law44, setLaw44] = useState(false);
    const [law223, setLaw223] = useState(false);
    const zoomedDiagram = useSelector((state) => state.zoomedDiagramSlice.zoomedDiagram);

    const toggleLaw44 = () => {
        setLaw44(!law44);
    };

    const toggleLaw223 = () => {
        setLaw223(!law223);
    };
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateMinDate, setEndDateMinDate] = useState(new Date(2018, 1, 1));
    const [endDateMaxDate, setEndDateMaxDate] = useState(new Date());
    const maxDate = new Date();
    maxDate.setDate(1);

    const activeFilterCount = 2;
    const clearAll = useClearAll();

    useEffect(() => {
        const storedDates = localStorage.getItem('dateForPickers');
        if (storedDates) {
            const datesArray = JSON.parse(storedDates);
            const startDates = datesArray.filter(date => date.type === 'start_date');
            const endDates = datesArray.filter(date => date.type === 'end_date');
            if (startDates.length > 0) {
                setStartDate(new Date(startDates[0].body));
                const newEndDateMinDate = new Date(startDates[0].body);
                setEndDateMinDate(newEndDateMinDate);
                const newEndDateMaxDate = new Date(newEndDateMinDate);
                newEndDateMaxDate.setFullYear(newEndDateMaxDate.getFullYear() + 3);
                setEndDateMaxDate(newEndDateMaxDate);
            }
            if (endDates.length > 0) {
                setEndDate(new Date(endDates[0].body));
            }
        }
    }, []);

    const handleStartDateChange = async (date) => {
        if (endDate && date > endDate) {
            console.log("Дата начала не может быть больше даты окончания");
            return;
        }
        setStartDate(date);
        setEndDateMinDate(date);

        const newMaxDate = new Date(date);
        newMaxDate.setFullYear(newMaxDate.getFullYear() + 3);
        setEndDateMaxDate(newMaxDate);

        let updatedEndDate = endDate;
        if (endDate > newMaxDate) {
            setEndDate(newMaxDate);
            updatedEndDate = newMaxDate;
        }

        await sendDatesToAPI(date, updatedEndDate);
        updateLocalStorageDates(date, updatedEndDate);
        dispatch(setSelectedDate(date.toISOString()));
    };

    const handleEndDateChange = async (date) => {
        if (startDate && date < startDate) {
            console.log("Дата окончания не может быть меньше даты начала");
            return;
        }
        setEndDate(date);
        const startDateToSend = startDate ? startDate : new Date();
        await sendDatesToAPI(startDateToSend, date);
        updateLocalStorageDates(startDateToSend, date);
        dispatch(setSelectedDate(date.toISOString()));
    };
    const sendDatesToAPI = async (start, end) => {
        const formattedStartDate = formatDateToSend(start);
        const formattedEndDate = formatDateToSend(end);
        try {
            await addRangeDate(formattedStartDate, formattedEndDate);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterComponentRef.current && !filterComponentRef.current.contains(event.target) &&
                !filterButtonRef.current.contains(event.target)) {
                setIsFilterVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

   /* const handletest = () => {
        setModalContent(
            <InfoTable
                onClose={() => {
                    setIsModalVisible(false);
                    setIsFullScreen(false);
                    setIsFullScreenAll(false)
                }}/>
        );
        setIsFullScreenAll(true);
        setIsFullScreen(true);
        setIsModalVisible(true);
    }*/

    useEffect(() => {
        const activeIndex = ['Извещения', 'Контракты', 'Исполнение', 'Пользовательский'].indexOf(activeTab);
        cancelAllPendingRequests();
        if (activeIndex !== -1 && activeIndex !== activeItem) {
            handleClick(activeIndex, activeTab);
        }
        if (activeIndex !== -1) {
            const { left: itemLeft, width } = itemsRef.current[activeIndex].getBoundingClientRect();
            const { left: parentLeft } = leftPartRef.current.getBoundingClientRect();
            const left = itemLeft - parentLeft;
            setSliderStyle({ width, left });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const handleClick = (index, tabName) => {
        if (zoomedDiagram) {
            return;
        }
        if (tabName === 'Пользовательский') {
            return;
        }
        if (activeTab !== tabName) {
            dispatch(setActiveTab(tabName));
            clearAll();
        }
        if (activeItem !== index) {
            setActiveItem(index);
            const { left: itemLeft, width } = itemsRef.current[index].getBoundingClientRect();
            const { left: parentLeft } = leftPartRef.current.getBoundingClientRect();
            const left = itemLeft - parentLeft;
            setSliderStyle({ width, left });
        }
    };

    const formatDateToSend = (date) => {
        const dateWithTimezone = addHours(date, 3);
        return formatISO(dateWithTimezone);
    };


    return (
        <div className={styles.content}>
            <div className={styles.leftPart} ref={leftPartRef}>
                {['Извещения', 'Контракты', 'Исполнение', 'Пользовательский'].map((item, index) => (
                    <div
                        ref={(el) => (itemsRef.current[index] = el)}
                        className={`${styles.item} ${activeItem === index ? styles.activeItem : ''}`}
                        onClick={() => handleClick(index, item)}
                        key={index}
                    >
                        {item}
                    </div>
                ))}
                <div className={styles.slider} style={sliderStyle} />
                <div className={styles.plusIcon}>
                    <img src={PlusIcon} alt="plus" />
                </div>
            </div>
            <div className={styles.iconContainer}>
                <div ref={filterComponentRef} className={`${styles.filterComponent} ${isFilterVisible ? styles.visible : ''}`}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date(2018, 1, 1)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<CustomDateButton />}
                    />
                    <img className={styles.calendarImg} src={calendarIcon} alt="calendar" />
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={endDateMinDate}
                        maxDate={endDateMaxDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<CustomDateButton />}
                    />
                    <div className={styles.checkboxContainer}>
                        <label className={styles.checkboxLabel}>
                            <CircleWithLabel
                                label="223-ФЗ"
                                filled={law44}
                                onClick={toggleLaw44}
                                borderColor="#CCC"
                                activeColor="#4A3DFD"
                                inactiveColor="#EDECFD"
                            />
                        </label>
                        <label className={styles.checkboxLabel}>
                            <CircleWithLabel
                                label="44-ФЗ"
                                filled={law223}
                                onClick={toggleLaw223}
                                borderColor="#CCC"
                                activeColor="#4A3DFD"
                                inactiveColor="#EDECFD"
                            />
                        </label>
                    </div>
                    <div ref={filterButtonRef} className={styles.iconButton} onClick={toggleFilterVisibility}>
                        <FilterIconWithBadge activeFilterCount={activeFilterCount} />
                    </div>
                </div>
               {/* <div ref={filterButtonRef} className={styles.iconButton} onClick={toggleFilterVisibility}>
                    <FilterIconWithBadge activeFilterCount={activeFilterCount} />
                </div>*/}
               {/* <button className={styles.iconButton} onClick={handletest}>
                    <img src={FilterAllIcon} alt="filter-all" />
                </button>
                <button className={styles.iconButton}>
                    <img src={Dots} alt="dots" />
                </button>*/}
            </div>

        </div>
    );
};

export default HeadDashContent;
